<template>
  <vx-card title="Categorias y Sub-categorías">
    <template #actions>
      <div>
        <vs-button
          v-if="$can('create', 'document')" size="small" color="success" type="border" icon-pack="feather"
          ref="createEntryBtn" class="mr-3" @click="createCategory"
        >
          Agregar Categoría
        </vs-button>
        <vs-button
            v-if="$can('create', 'document')" size="small" color="success" type="border" icon-pack="feather"
            ref="createEntryBtn"  @click="createSubcategory"
        >
          Agregar Sub-categoría
        </vs-button>
      </div>
    </template>

    <div class="vx-row">
      <div class="vx-col md:w-1/4 w-full mt-10">
        <div class="m-3 mt-10">
          <div>
            <ul class="centerx">
              <div class="mt-4"><b>Papelera de reciclaje</b></div>
              <li>
                <vs-checkbox v-model="recycleBin" class="mt-4">Si</vs-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <vs-tabs class="w-3/4">
        <vs-tab label="Categorías">
          <div class="vx-col w-full">
            <vs-table max-items="10" pagination search stripe :data="categories" :hoverFlat="true">
              <template #thead>
                <vs-th sort-key="name">Nombre</vs-th>
                <vs-th>Acciones</vs-th>
              </template>

              <template v-slot="{data}">
                <tbody>
                <vs-tr v-for="(record, index) in data" :data="record" :key="index">
                  <vs-td><p class="font-medium">{{record.name}}</p></vs-td>
                  <vs-td>
                    <div class="flex">
                      <template v-if="!recycleBin">
                        <vx-tooltip text="Editar">
                          <vs-button
                            :disabled="!($can('retrieve', 'document') || $can('update', 'document'))" radius
                            size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                            @click.stop="updateCategory(record)"
                          />
                        </vx-tooltip>

                        <vx-tooltip text="Eliminar">
                          <vs-button
                            :disabled="!$can('delete', 'document')"
                            radius size="large" color="warning" type="flat" icon-pack="feather" icon="icon-trash-2"
                            @click.stop="deleteCategory(record)"
                          />
                        </vx-tooltip>
                      </template>

                      <vx-tooltip text="Restaurar" v-else>
                        <vs-button
                          :disabled="!($can('create', 'document') || $can('update', 'document'))"
                          radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-refresh-cw"
                          @click="restoreCategory(record)"
                        />
                      </vx-tooltip>
                    </div>
                  </vs-td>
                </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </div>
        </vs-tab>

        <vs-tab label="Subcategorías">
          <div class="vx-col w-full">
            <vs-table max-items="10" pagination search stripe :data="subcategories" :hoverFlat="true">
              <template #thead>
                <vs-th sort-key="name">Nombre</vs-th>
                <vs-th sort-key="categoryName">Categoría</vs-th>
                <vs-th>Acciones</vs-th>
              </template>

              <template v-slot="{data}">
                <tbody>
                <vs-tr v-for="(record, index) in data" :data="record" :key="index">
                  <vs-td><p class="font-medium">{{record.name}}</p></vs-td>
                  <vs-td><p class="font-medium">{{record.categoryName}}</p></vs-td>
                  <vs-td>
                    <div class="flex">
                      <template v-if="!recycleBin">
                        <vx-tooltip text="Editar">
                          <vs-button
                            :disabled="!($can('retrieve', 'document') || $can('update', 'document'))" radius
                            size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                            @click.stop="updateSubcategory(record)"
                          />
                        </vx-tooltip>

                        <vx-tooltip text="Eliminar">
                          <vs-button
                            :disabled="!$can('delete', 'document')"
                            radius size="large" color="warning" type="flat" icon-pack="feather" icon="icon-trash-2"
                            @click.stop="deleteSubcategory(record)"
                          />
                        </vx-tooltip>
                      </template>

                      <vx-tooltip text="Restaurar" v-else>
                        <vs-button
                          :disabled="!($can('create', 'document') || $can('update', 'document'))"
                          radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-refresh-cw"
                          @click.stop="restoreSubcategory(record)"
                        />
                      </vx-tooltip>
                    </div>
                  </vs-td>
                </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </div>
        </vs-tab>
      </vs-tabs>

      <vs-popup :title="popupTitle" :active.sync="popupActive">
        <template v-if="entry === 'category'">
          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <label for="name">Nombre Categoría:</label>
              <vs-input id="category-name" v-model="models.categoryModel.name" class="w-full" name="category-name"/>
            </div>
          </div>

          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <div class="flex flex-wrap justify-between">
                <vs-row vs-type="flex" vs-justify="flex-end">
                  <vs-button size="small" color="danger" type="border" class="mr-3 mb-2" @click="popupDismiss">
                    Cancelar
                  </vs-button>
                  <vs-button size="small" class="mb-2" @click="createOrUpdateEntry(entry)" :disabled="!isFormValid">
                    Guardar
                  </vs-button>
                </vs-row>
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <label for="name">Nombre Subcategoría:</label>
              <vs-input name="subcategory-name" id="subcategory-name" v-model="models.subcategoryModel.name" class="w-full"/>
            </div>
          </div>

          <div class="vs-row mb-5">
            <div class="vx-col w-full">
              <label for="subcategory-category">Categoría Padre:</label>
              <v-select
                  id="subcategory-category"
                  v-model="models.subcategoryModel.category"
                  class="vx-col w-full select-large"
                  index="id"
                  label="name"
                  name="subcategory-category"
                  :options="categories"
                  :placeholder="'Seleccione...'"
                  :searchable="true"
              />
            </div>
          </div>

          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <div class="flex flex-wrap justify-between">
                <vs-row vs-type="flex" vs-justify="flex-end">
                  <vs-button size="small" color="danger" type="border" class="mr-3 mb-2" @click="popupDismiss">
                    Cancelar
                  </vs-button>
                  <vs-button size="small" class="mb-2" @click="createOrUpdateEntry(entry)" :disabled="!isFormValid">
                    Guardar
                  </vs-button>
                </vs-row>
              </div>
            </div>
          </div>
        </template>
      </vs-popup>
    </div>
  </vx-card>
</template>

<script>
  import FilterGroups from "@/components/filter-groups/FilterGroups.vue";
  import vSelect from "vue-select";
  import CategoriesClient from "@/utils/categoriesClient";
  import SubCategoriesClient from "@/utils/subcategoriesClient";
  import Notifier from "@/utils/notification";
  import * as notificationMixins from "@/utils/notificationMixins";

  let notifier = new Notifier();
  const categoriesClient = new CategoriesClient();
  const subcategoriesClient = new SubCategoriesClient();

  export default {
    components: {
      FilterGroups,
      vSelect,
    },

    data() {
      return {
        categories: [],
        entry: null,
        entries: [],
        filters: [
          {
            name: "category",
            label: "Categorías",
            filters: [],
          },
          {
            name: "subcategory",
            label: "Subcategorías",
            filters: [],
          }
        ],
        models: {
          categoryModel: {
            name: "",
          },
          subcategoryModel: {
            name: "",
            category: {},
          }
        },
        popupActive: false,
        popupTitle: "",
        queryStrings: {},
        recycleBin: false,
        subcategories: [],
      }
    },

    computed: {
      isFormValid() {
        if (this.entry === "category"){
          return this.models.categoryModel.name !== "";
        } else if (this.entry === "subcategory") {
          return (this.models.subcategoryModel.category && this.models.subcategoryModel.name !== "");
        } else {
          return false;
        }
      },
    },

    watch: {
      async recycleBin() {
        await this.getEntries();
      },
    },

    created() {
      this.getEntries();
    },

    methods: {
      createCategory() {
        this.entry = "category";
        this.popupTitle = "Nueva Categoría";
        this.popupActive = true;
      },

      updateCategory(category) {
        this.models.categoryModel = category;
        this.entry = "category";
        this.popupTitle = "Modificar categoría";
        this.popupActive = true;
      },

      async deleteCategory(category) {
        notificationMixins.DeleteEntryNotification.fire()
          .then(async (result) => {
            if (result.value){
              try{
                this.$vs.loading();
                await categoriesClient.delete({
                  pathParameters: {id: category.id},
                });
                await notifier.notification("deleted");
              } catch(_) {
                await notifier.notification("error");
              } finally {
                this.$vs.loading.close();
                await this.getEntries();
              }
            }
          });
      },

      restoreCategory(category) {
        notificationMixins.RestoreEntryNotification.fire()
          .then(async (result) => {
            if (result.value){
              try{
                this.$vs.loading();
                await categoriesClient.restore({pathParameters: {id: category.id}});
                await notifier.notification("restored");
              } catch {
                await notifier.alertMessage("error");
              } finally {
                this.$vs.loading.close();
                await this.getEntries();
              }
            }
          });
      },

      createSubcategory() {
        this.entry = "subcategory";
        this.popupTitle = "Nueva Subcategoría";
        this.popupActive = true;
      },

      updateSubcategory(subcategory) {
        this.models.subcategoryModel = subcategory;
        this.entry = "subcategory";
        this.popupTitle = "Modificar Subcategoría";
        this.popupActive = true;
      },

      async deleteSubcategory(subcategory) {
        notificationMixins.DeleteEntryNotification.fire()
          .then(async (result) => {
            if (result.value){
              try{
                this.$vs.loading();
                await subcategoriesClient.delete({pathParameters: {id: subcategory.id}})
                await notifier.notification("deleted");
              } catch(_) {
                await notifier.notification("error");
              } finally {
                this.$vs.loading.close();
                await this.getEntries();
              }
            }
          });

      },

      restoreSubcategory(subcategory) {
        notificationMixins.RestoreEntryNotification.fire()
          .then(async (result) => {
            if (result.value){
              try{
                this.$vs.loading();
                await subcategoriesClient.restore({pathParameters: {id: subcategory.id}});
                await notifier.notification("restored");
              } catch {
                await notifier.alertMessage("error");
              } finally {
                this.$vs.loading.close();
                await this.getEntries();
              }
            }
          });
      },

      async createOrUpdateEntry(entryType) {
        try{
          this.$vs.loading();
          switch (entryType) {
            case "category":
              if (this.models.categoryModel.id){
                await categoriesClient.update({
                  pathParameters: {id: this.models.categoryModel.id},
                  data: this.models.categoryModel,
                });
                this.$vs.loading.close();
                await notifier.notification("updated");
              } else {
                await categoriesClient.create({data: this.models.categoryModel});
                this.$vs.loading.close();
                await notifier.notification("created");
              }
              await this.getEntries();
              break;
            case "subcategory":
              if(this.models.subcategoryModel.id){
                await subcategoriesClient.update({
                  pathParameters: {id: this.models.subcategoryModel.id},
                  data: this.models.subcategoryModel,
                });
                this.$vs.loading.close();
                await notifier.notification("updated");
              } else {
                await subcategoriesClient.create({data: this.models.subcategoryModel});
                this.$vs.loading.close();
                await notifier.notification("created");
              }
              await this.getEntries();
              break;
          }
        } catch (_) {
          this.$vs.loading.close();
          await notifier.notification("error")
        } finally {
          this.popupActive = false;
        }
      },

      async getEntries() {
        this.categories = [];
        this.subcategories = [];
        try {
          this.$vs.loading();
          const categoriesResponse = await categoriesClient.search({
            fromRecycleBin: this.recycleBin,
            queryStrings: {
              stage: "staging",
              page_size: "all",
            }
          });
          this.categories = categoriesResponse.data?.results || [];

          const subcategoriesResponse = await subcategoriesClient.search({
            fromRecycleBin: this.recycleBin,
            queryStrings: {
              stage: "staging"
            }
          });
          this.subcategories = subcategoriesResponse.data.results.map((subcategory) => {
            return {...subcategory, categoryName: subcategory.category?.name || "-"};
          });
        } catch (error) {
          await notifier.notification("error");
        } finally {
          this.$vs.loading.close();
        }
      },

      popupDismiss() {
        this.popupActive = false;
        this.models.categoryModel = {
          name: ""
        };
      },
    },
  }
</script>

<style>
  .vs-popup--content{
    overflow: visible;
  }
</style>
